import axios from 'axios';
function getGuideInfo (params, baseUrl) {
    return axios({
        url: '/api/school/guideInfo/getGuideInfo',
        method: 'post',
        data: params,
      }).then((res) => {
        if (res.status == '200') {
          return res.data;
        }
      }).catch((err) => {
        throw err;
      })
}

function saveGuideInfo (params, baseUrl) {
    return axios({
        url: '/api/school/guideInfo/saveGuideInfo',
        method: 'post',
        data: params,
    }).then((res) => {
        if (res.status == '200') {
            return res.data;
        }
    }).catch((err) => {
        throw err;
    })
}

export {
    getGuideInfo,
    saveGuideInfo
}
