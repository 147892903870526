class Guiding {
    static create (option) {
        new Guiding(option)
    }

    get getCurrentDom () {
        try {
            return this.data[this.currentIdx].nodeId && document.querySelector(this.data[this.currentIdx].nodeId)
        } catch (e){}

    }

    get getCurrentDomPlacement () {
        if (this.data.length > 0 && typeof this.data[this.currentIdx] === 'object') {
            return [
                this.data[this.currentIdx].filePath,
                this.data[this.currentIdx].fileName,
                this.data[this.currentIdx].stepId,
            ]
        }
    }

    constructor (option) {
        this.el = document.querySelector(option.el);
        this.data = option.data;
        this.finish = option.finish;
        this.currentStep = option.currentStep;

        this.elBtnGroup = null;
        this.currentIdx = 0;
        this.currentNode = null;
        this.previousOneNode = null;

        this.debouncedMethod = this.debounce(this.repaint);

        this.init();
    }

    async init () {
        await this.render()
        this.getManipulate()
        this.bindEvent()
    }

    async render () {
        await this.getFunctionalityDom();
        const oFrag = document.createDocumentFragment();
        const tpl = await this.getIntroductory();
        if (Object.prototype.toString.call(tpl) !== '[object HTMLDivElement]') return;
        oFrag.appendChild(tpl);
        this.currentNode && oFrag.appendChild(this.currentNode);
        this.el.appendChild(oFrag);
        console.log('render')
        this.setPreviousOneNode();
    }

    getFunctionalityDom () {
        try {
            this.currentNode && this.el.removeChild(this.currentNode);
            this.currentNode = null;
        } catch(e){};

        if (this.getCurrentDom) {
            this.currentNode = this.getCurrentDom.cloneNode(true);

            const { x, y, width, height } = this.getCurrentDom.getBoundingClientRect();
            this.currentNode.style = `
                margin-left: ${x}px;
                margin-top: ${y}px;
                background-color: #FFFFFF;
                width: ${width}px;
            `
        }

    }

    async getIntroductory () {
        try {
            this.previousOneNode && this.el.removeChild(this.previousOneNode);
            const [ filePath, fileName, stepId] = this.getCurrentDomPlacement || [];
            const tpl = await this.compositionDom(filePath, fileName, stepId);

            return tpl;
        } catch(e) {}
    }

    setPreviousOneNode () {
        const [, , stepId] = this.getCurrentDomPlacement;
        this.previousOneNode = document.getElementById(stepId);
    }

    getManipulate () {
        this.elBtnGroup = this.el.querySelectorAll('.xk-guiding-btn-wrapper')[0]
    }

    bindEvent () {
        console.log(this.elBtnGroup,'[绑定按钮]')
        this.elBtnGroup.addEventListener('click', this.handlerBtnClick.bind(this))
        window.addEventListener('resize', this.onResize.bind(this), true)
    }

    removeEvent () {
        this.elBtnGroup && this.elBtnGroup.removeEventListener('click', this.handlerBtnClick)
    }

    handlerBtnClick (ev) {
        const e = ev || window.event,
            target = e.target || e.srcElement,
            tagName = target.tagName.toLowerCase(),
            EVENTNAME = 'button';
        console.log(EVENTNAME, '[触发了按钮]')
        if (EVENTNAME === tagName){
            const type = target.getAttribute('data-method');
            this.removeEvent();

            /**
             * previousStep 上一步
             * skipOver 跳过
             * experience 立即体验
             * nextStep 下一步
             * */
            switch (type) {
                case 'previousStep':
                    this.currentIdx -= 1;
                    this.init ();
                    break;
                case 'skipOver':
                    this.clearData()
                    this.finish('skipOver');
                    break;
                case 'experience':
                    this.clearData()
                    this.finish('experience');
                    break;
                case 'nextStep':
                    if (this.data.length == 1) {
                        this.clearData()
                        this.finish('finish');
                    } else if (this.currentIdx === this.data.length - 1){
                        this.clearData()
                        this.finish('finish');
                    } else {
                        this.currentIdx += 1;
                        this.init ();
                    }

                    break;
                default:
                    break;
            }

            this.currentStep((this.currentIdx + 1) + '/' + this.data.length)
        }
    }

    async compositionDom (filePath, fileName, stepId) {
        if(!filePath || !fileName) return;
        const pullInto = () => require.ensure([], (require) => require(`./components/${filePath}/${fileName}/index.js`) );
        let dom = null;

        await pullInto().then(module => {
            dom = new module.default(stepId).tpl();
        }).catch(err => {
            console.log(err)
        })

        return dom;
    }

    onResize () {
        try {
            this.currentNode && this.el.removeChild(this.currentNode);
            this.currentNode = null;
        } catch(e){};
        this.debouncedMethod();
    }

    repaint () {
        this.removeEvent();
        this.init();
    }

    clearData () {
        this.elBtnGroup = null;
        this.currentIdx = null;
        this.currentNode = null;
        this.previousOneNode = null;
    }

    debounce (originalMethod) {
        let timeout;
        return function() {
          const args = arguments;
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            originalMethod.apply(this, args); // 修改这里，直接调用 originalMethod(...args)
          }, 500);
        };
    }

};

export default Guiding;
