/**
 * menuId 主键ID,遇到相同路由的name即会当作条件判断
 * moduleKey 和 version 为后台需要的入参，其中 version 如果版本变化或者优化扩展，则需要进行修改，比如修改为 1.1
 * finish 点击跳过或者我知道了（结束）的出参， type 为类型 open | router，path为 router的跳转地址。
 *
 * */

export default [
    {
        menuId: "FirstPage",
        moduleKey: 'feedback',
        version: '1',
        finish: () => (
            {
                type: 'OPEN',
                module: 'EVENT_OPEN_FEEDBACK',
                pathName: '',
            }
        ),
        step: () => ([
            {
                nodeId: '#J_FEEDBACK',
                stepId: 'J_FEEDBACK_STEP1',
                title: '意见反馈',
                text: '倾听用户心声，不断优化体验。 点击【意见反馈】，您的反馈将成为 我们前进的动力！',
                filePath: 'Feedback',
                fileName: 'step1',
            }
        ])
    }
]
