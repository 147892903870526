<template>
    <div
        v-if="showGuiding"
        id="J_GUIDING"
        class="guiding-wrapper"
    ></div>
</template>
<script>
import { mapState } from 'vuex';
import guidingEventMap from './data/guidingEventMap.js';
import Guiding from './Guiding.js'
import { getGuideInfo, saveGuideInfo } from './http/index.js';
import { debounce } from './libs/fnProcessing'
export default {
    name: "Guiding",
    props: {
        routerInfo: Object,
    },
    data () {
        return {
            agencyApi: process.env.VUE_APP_AGENCY_API, // 接口请求地址
            guidingEventMap,
            showGuiding: false,
            params: {}
        }
    },
    computed: {
        ...mapState({
            userId: state => state.userId
        })
    },
    created ()  {
        this.init()
    },
    methods: {
        init:debounce (function(){
            if (this.routerInfo.name === 'login') {
                this.showGuiding = false
                return
            }
            const initGuidingStatus = this.isCheckShowGuiding();
            this.initPageData(initGuidingStatus)
        }, 2000, true),
        isCheckShowGuiding () {
            let _this = this;
            return new Promise((resolve) => {
                const { name } = _this.routerInfo;
                const status = _this.guidingEventMap.map(item => item.menuId).includes(name)
                resolve(status)
            })
        },
        async initPageData (initGuidingStatus) {
            let _this = this;
            let initData = null;

            try {
                await initGuidingStatus.then((res) => {
                    if (res) {
                        const { name } = _this.routerInfo;
                        initData = _this.guidingEventMap.filter(item => item.menuId === name)[0];
                    }
                })

                const { moduleKey, version } = initData;
                this.params = {
                    'moduleKey': moduleKey,
                    'version': version,
                    'userId': this.userId
                };

                await getGuideInfo(this.params, this.agencyApi).then((res) => {
                    if (res.code === '200') {
                        if (res.data) {
                            _this.showGuiding = true;
                            setTimeout(() => {
                                _this.formattedData(initData);
                            }, 100)
                        }
                    }
                })
            } catch(e){}
        },
        formattedData (initData) {
            const STEP_JSON = initData.step();
            let _this = this;
            Guiding.create({
                el: '#J_GUIDING',
                data: STEP_JSON,
                finish (status) {
                    _this.finish(status, initData)
                    _this.showGuiding = false;
                },
                currentStep (currentIdx) {
                    console.log(currentIdx, 'currentStep')
                }
            });
        },
        finish (status, initData) {
            const finishData = initData.finish();
            const { type, module, pathName } = finishData;
            let paramType

            switch (status) {
                case 'skipOver':
                    console.log('跳过')
                    break;
                case 'finish':
                    console.log('完成')
                    paramType = 2
                    break;
                case 'experience':
                    console.log('进行操作')
                    if (type === 'OPEN') {
                        this.$emit('openFunctionality', module)
                    } else if (type == 'ROUTER') {
                        this.$rouer.push({name: pathName})
                    }
                    paramType = 1
                    break;
                default:
            }
            this.saveGuideData(paramType);
        },
        saveGuideData (type) {
            saveGuideInfo({ type, ...this.params }, this.agencyApi).then((res) => {
                if (res.code === '200') {
                    console.log(res,'引导页保存成功')
                }
            })
        }
    },
    watch: {
        routerInfo: {
            handler (newVal) {
                this.init()
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
@import url('./css/index.scss');
    .guiding-wrapper {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 99999;  // 不知道项目中层级，所以写5个9
        background-color: rgba($color: #000000, $alpha: 0.5);
    }
</style>
