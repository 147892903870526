//截流
function throttle (fn, delay) {
    var t = null,
        begin = new Date().getTime();

    return function () {
        var _self = this,
            args = arguments,
            cur = new Date().getTime();

        clearTimeout(t);

        if(cur - begin >= delay) {
            fn.apply(_self, args);
            begin = cur
        } else {
            t = setTimeout(function () {
                fn.apply(_self, args);
            },delay)
        }
    }
}
//防抖
function debounce (fn, delay,triggleNow) {
    var t = null;

    var debounced = function () {
        var _self = this,
            args = arguments;

        if(t) {
            clearTimeout(t);
        }

        if(triggleNow) {
            var exec = !t;

            t= setTimeout(function(){
               t = null;
            },delay)

            if(exec){
                fn.apply(_self, args)
            }
        } else {
            t= setTimeout(function(){
                fn.apply(_self, args)
             },delay)
        }

    }

    debounced.remove = function() {
        clearTimeout(t);
        t = null;
    }

    return debounced
}
export {
    throttle,debounce
}
