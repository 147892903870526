// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/loaders/stylePostLoader.js!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!./css/index.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".guiding-wrapper[data-v-78077308]{position:fixed;top:0;right:0;bottom:0;left:0;z-index:99999;background-color:rgba(0,0,0,.5)}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
